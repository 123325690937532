import { track } from "@/lib/analytics"
import { useInView } from "framer-motion"
import { useEffect, useRef, useState } from "react"

/**
 * Use track fire once when component in view for the first time
 */
interface Props {
  event: string
  properties?: Record<string, any>
}
export function InViewTrack({ event, properties }: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const isInview = useInView(ref)
  const [isFired, setIsFired] = useState<boolean>(false)
  useEffect(() => {
    if (isInview && !isFired) {
      track(event, properties)
      setIsFired(true)
    }
  }, [isInview])
  return <div ref={ref} />
}

export function TrackTime() {
  const [start] = useState<number>(Date.now())
  useEffect(() => {
    function sendTimeSpend() {
      track("TimeSpend", {
        timespend: Date.now() - start,
      })
    }
    window.addEventListener("beforeunload", sendTimeSpend)
    return () => {
      window.removeEventListener("beforeunload", sendTimeSpend)
    }
  }, [])
}
